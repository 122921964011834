import { IABTestData } from '@base/lib/abTest'
import Vue from 'vue'
import Vuex from 'vuex'
import { VuexModule, Module, Action, Mutation, getModule } from 'vuex-module-decorators'
Vue.use(Vuex)
const store = new Vuex.Store({})

interface ICoupon {
  couponStayPopup: boolean
  type: string
  abTestGroup?: IABTestData
}

@Module({ dynamic: true, store, name: 'stayPopupActivitives' })
class StayPopup extends VuexModule {
  couponStayPopup = false
  couponType = ''
  couponShowPopup = false
  abTestGroup = {
    extraInfo: '',
    groupName: '',
  }
  @Mutation
  setCouponStayPopup({ couponStayPopup, type, abTestGroup }: ICoupon) {
    this.couponStayPopup = couponStayPopup
    this.couponType = type
    this.couponShowPopup = false
    this.abTestGroup = abTestGroup || this.abTestGroup
  }
  @Mutation
  setCouponShowPopup(isShow: boolean) {
    this.couponShowPopup = isShow
  }
  @Action({ commit: 'setCouponStayPopup' })
  updateCouponStayPopup({ couponStayPopup, type, abTestGroup }: ICoupon) {
    console.log('updateCouponStayPopup', couponStayPopup, type, abTestGroup)
    return { couponStayPopup, type, abTestGroup }
  }
  @Action({ commit: 'setCouponShowPopup' })
  showCouponStayPopup() {
    return true
  }
}
export const StayPopupModule = getModule(StayPopup)
