import axios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios'
import 'requestidlecallback-polyfill'
import { AD_CHANNEL, ApiWhiteList } from '@base/lib/constant'
import { getItem, removeItem, setSession } from '@base/lib/storage-handler'
import { getChannel, checkLocalChannel, getUrlChannel, getLocalChannel, removeQuote } from '@base/lib/checkChannel'
import { ErrorCode, DeviceTypeEnum } from '@base/enum/common'
import { IResponseError } from '@base/interface/network'
import { isApp, compactObject, getLanguage, getUuid, getWebViewType, getClientEnv } from '@base/lib/utils'
import { checkDefaultChannel, getXUserAgent, getMarketChannel, removeComparedChannel } from '@base/lib/channelUtil'
import { sentryHandle } from '@base/lib/sentry-utils'
import { commonBridgeFn, getXAppVersion } from '@base/lib/common-bridge'
import { trackEvent } from '@pintar/id-track-event'
import dsBridge from '@base/plugins/dsbridge'
import { blobToDataURI, dataType } from '@base/lib/image-exif'
import { CommonModule } from '@base/store/common'

const isWeb = process.env.VUE_APP_PROJECT_NAME === 'kp-id-web'
// 1 hours
export const cacheTimestamp = 1 * 60 * 60 * 1000
const whiteChannelList = [
  'MGM_WASHARE',
  'MGM_FBSHARE',
  'MGM_CPLINKSHARE',
  'MGM_SMSSHARE',
  'MGM_LINESHARE',
  'MGM_DEFAULT',
]

export const handleCheckChannel = async () => {
  const originUrlChannel = getUrlChannel()
  const urlChannel = getUrlChannel()?.toUpperCase()
  const localChannel = getLocalChannel()
  if (checkLocalChannel()) return
  const id = getUuid()
  try {
    const res = await axios.get(`/api/operation/channel-registrations/find/${urlChannel}`)
    const channel = removeQuote(res.data)
    // If the channel in the url is inconsistent with the channel returned by the interface,
    //it means that the channel in the url has been downloaded and you need to log in again.
    if (urlChannel !== channel) {
      console.log('##### channel is inconsistent', urlChannel, channel)
      await CommonModule.handleLogout()
    }
    setSession(AD_CHANNEL, channel, cacheTimestamp)
    removeItem(AD_CHANNEL)
    // checkDefaultChannel(res.data)
    const route = window.vue.$route
    const query: { redirect?: string } = route.query || {}
    const comparedChannel = getItem('comparedChannel')
    console.log('##### comparedChannel', comparedChannel)
    // The locally cached session channel has expired and the channel has changed. You need to log in again.
    if (!whiteChannelList.includes(channel) && comparedChannel && comparedChannel !== channel) {
      console.log('##### comparedChannel', comparedChannel, channel)
      removeItem('capp_phone_num')
      removeItem('channelToken')
      removeItem('loginStatus')
      await CommonModule.handleLogout()
      return
    }
    window.vue.$route.params.channel = res.data
    trackEvent.updateDeviceInfo({
      source: isApp() ? '' : channel,
    } as any)
    console.log('%c##### checkChannel', 'color: green', route, urlChannel, channel)
    // To prevent the failure to obtain routing data, replace the path in the URL
    if (route.name === null && route.fullPath === '/') {
      const pathname = location.pathname.replace(originUrlChannel, channel) + location.search
      console.log('##### checkChannel', 'route is null, use location', pathname)
      window.vue.$router.push({
        path: pathname,
      })
      return
    }
    const path = route.path.replace(originUrlChannel, channel)
    console.log('%c##### checkChannel', 'color: red', path)
    window.vue.$router.push({
      path,
      query,
    })
  } catch (error) {
    checkDefaultChannel(localChannel || urlChannel)
    setSession(AD_CHANNEL, removeQuote(localChannel || urlChannel), cacheTimestamp)
    window.vue.$handleError(error)
  }
}

const requestInterceptor = async (config: AxiosRequestConfig) => {
  const headers = {
    'Accept-Language': getLanguage(),
    'x-user-agent': isWeb ? getXUserAgent(getLanguage()) : '',
    'x-adv-market-channel': isWeb ? getMarketChannel() : '',
    'x-app-version': getXAppVersion(),
    'x-os-type': getWebViewType(),
    'x-adv-uuid': isWeb ? getUuid() : commonBridgeFn('getDeviceUUID', false),
  }
  config.headers = { ...config.headers, ...compactObject(headers) }

  config.params = {
    ...config.params,
    channel: isWeb ? getChannel() : '',
    lang: getLanguage(),
  }
  if (!config.params.channel) {
    delete config.params.channel
  }
  return config
}

const responseInterceptor = (response: AxiosResponse) => {
  return response
}

const responseErrorInterceptor = (error: any) => {
  const response = error.response as AxiosResponse<IResponseError>
  if (response?.status === 400 && response.data.code !== ErrorCode.LOGIN_FAILED) {
    if (ApiWhiteList.some((item) => response.config.url?.includes(item))) {
      window.requestIdleCallback(() => sentryHandle(response, 400, 'captureMessage'), { timeout: 3000 })
    } else {
      window.requestIdleCallback(() => sentryHandle(response, 400, 'captureException'), { timeout: 3000 })
    }
  }
  if (response?.status === 401) {
    window.requestIdleCallback(() => sentryHandle(response, 401, 'captureMessage'), { timeout: 3000 })
  }
  if (response?.status >= 500) {
    window.requestIdleCallback(() => sentryHandle(response, response?.status, 'captureException'), { timeout: 3000 })
  }
  return Promise.reject(error)
}

export const mergeParams = (params: AxiosRequestConfig = { baseURL: '/api' }) => {
  const instance: AxiosInstance = axios.create({
    baseURL: params.baseURL ? params.baseURL : '/api',
    timeout: 60000,
  })

  instance.interceptors.request.use(requestInterceptor)
  instance.interceptors.response.use(responseInterceptor, responseErrorInterceptor)
  return instance
}

// ios requestInterceptor
class IosRequestInterceptor {
  base = '/api'
  get<T = any, R = AxiosResponse<T>, D = any>(url: string, data = {}): Promise<R> {
    return AxiosInstanceNew.get(url, data)
  }
  post<T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    data?: null | {},
    headers?: { headers?: {} },
  ): Promise<AxiosResponse<T>> {
    if (getClientEnv() === DeviceTypeEnum.IOS) {
      const params = url.includes('?') ? `&lang=${getLanguage()}` : `?lang=${getLanguage()}`
      return iosRequest('POST', '/api' + url + params, data, headers?.headers)
    }
    return AxiosInstanceNew.post(url, data, headers)
  }
  put(url: string, params = {}) {
    return AxiosInstanceNew.put(url, params)
  }
  delete(url: string, data = {}) {
    return AxiosInstanceNew.delete(url, data)
  }
}
const defaultHeaders = {
  'Accept-Language': getLanguage(),
  'x-app-version': getXAppVersion(),
  'x-os-type': getWebViewType(),
}
const iosRequest = async (method: string, url: string, data: any, headers?: {}): Promise<AxiosResponse<any>> => {
  // const query = dataToQuery(data?.params) ? '&' + dataToQuery(data?.params) : ''
  let datas = { file: '' }
  if (dataType(data) === 'formdata') {
    const blob: Blob = data.get('file')
    const base64 = await blobToDataURI(blob)
    datas.file = base64
  } else {
    datas = data
  }

  const dataHeader = data?.headers || headers || {}
  console.log('iosRequest', datas)
  return new Promise((resolve, reject) => {
    dsBridge.call(
      'proxyRequest',
      {
        method,
        url,
        data: datas,
        headers: {
          ...dataHeader,
          ...compactObject(defaultHeaders),
        },
      },
      (response) => {
        console.log(response)
        // success
        if (response.status >= 200 && response.status < 400) {
          resolve(response)
        } else {
          console.log('iosRequest error', response)
          responseErrorInterceptor({ response })
          reject({ response })
        }
      },
    )
  })
}
const AxiosInstanceNew = mergeParams()
export const IosInstance = new IosRequestInterceptor()
export default AxiosInstanceNew
