
import { Component, Prop } from 'vue-property-decorator'
import { Component as VueComponent } from 'vue-tsx-support'
import { Subscription } from 'xstream'
import { scrollStream } from '@base/lib/stream'
import { goHomeBase, isApp } from '@base/lib/utils'
import { commonBridgeFn, goBackByNative, hasNativeMethod } from '@base/lib/common-bridge'
import { setItem } from '@base/lib/storage-handler'
import StayPop from '@base/components/StayPop.vue'
import { StayPopupModule } from '@base/store/stay-popup'
import dayjs from 'dayjs'
import BottomStayPopUp from './BottomStayPopUp.vue'

interface IProps {
  fixed?: boolean
  hasBack?: boolean
  allStyle?: any
  color?: string
  title?: string
  canlisten?: boolean
  preventDefaultEvent?: boolean
  selfSvg?: boolean
  preventFinish?: boolean
  bottomBorder?: boolean
  handleBack?: (callback?: () => void) => void
  isBackFunction?: boolean
  bottomShadow?: boolean
}

@Component
export default class PageTitle extends VueComponent<IProps, {}> {
  @Prop({
    type: Object,
    default: () => {
      return {
        background: '',
        opacity: 1,
      }
    },
  })
  allStyle!: any
  @Prop({ type: String, default: '#101010' }) color!: string
  @Prop({ type: String, default: '' }) title!: string
  @Prop({ type: Boolean, default: true }) hasBack!: boolean
  @Prop({ type: Boolean, default: true }) fixed!: boolean
  @Prop({ type: Boolean, default: false }) canlisten!: boolean
  @Prop({ type: Boolean, default: false }) preventDefaultEvent!: boolean
  @Prop({ type: Boolean, default: false }) selfSvg!: boolean
  @Prop({ type: Boolean, default: false }) preventFinish!: boolean
  @Prop({ type: Boolean, default: false }) webview!: boolean
  @Prop({ type: Boolean, default: false }) bottomBorder!: boolean
  @Prop({ type: Boolean, default: true }) bottomShadow?: boolean
  @Prop({ type: Boolean, default: true }) hasStayPopup?: boolean
  @Prop({ type: Boolean, default: false }) needCustomBack!: boolean
  @Prop({ type: Boolean, default: false }) isBackFunction?: boolean

  private pageYOffset = 0
  private subs: Subscription[] = []
  private excludes: string[] = ['BPJS', 'Pintar Score Q&A', 'Bank Jago', 'KTP', 'SMS']
  private hasNotice = false //

  private noticeMessage =
    'Layanan Kredit Pintar akan nonaktif sementara karena ada pemeliharaan sistem pada tanggal 10 Desember 2019, pukul 01:00 - 04:00 WIB. Mohon maaf atas ketidaknyamanannya.'

  get shadowPercentage() {
    if (this.pageYOffset >= 85) {
      return 1
    }
    return this.pageYOffset / 85
  }
  get active() {
    if (this.canlisten) {
      return this.pageYOffset >= 85
    }
    return true
  }
  get couponStayPopup() {
    const { couponStayPopup } = StayPopupModule
    return couponStayPopup
  }
  get couponType() {
    const { couponType } = StayPopupModule
    return couponType
  }
  get couponShowPopup() {
    const { couponShowPopup } = StayPopupModule
    return couponShowPopup
  }
  get hasLinearApply() {
    return this.$route.name?.includes('Linear')
  }
  get pageTitle() {
    const dontChange = this.excludes.some((item: any) => {
      return this.title.includes(item)
    })

    if (dontChange) {
      return this.title
    }
    return this.title.toLowerCase().replace(/^\S/g, function (s) {
      return s.toUpperCase()
    })
  }
  handleRealLeave() {
    if (this.couponStayPopup) {
      StayPopupModule.setCouponShowPopup(true)
      return
    }

    this.back()
  }
  async leave() {
    if (this.isBackFunction && (window.vue.isShowPopUpLast === undefined || !window.vue.isShowPopUpLast)) {
      this.$emit('handleBack', this.handleRealLeave)
    } else {
      window.vue.isShowPopUpLast = false
      this.handleRealLeave()
    }
  }
  back() {
    if (this.couponType.includes('retainCouponStayPopup')) {
      if (this.hasLinearApply || this.$route.meta?.backTo === 'Home') goHomeBase()
      else this.$router.back()
      return
    }

    this.$emit('back')

    if (this.needCustomBack) {
      return
    }
    if (!this.preventDefaultEvent) {
      // window.dkBridge?.goBack?.()
      goBackByNative()
    } else if (isApp() && !this.webview) {
      if (hasNativeMethod('launchHomePage') && !this.preventFinish) {
        // window.dkBridge.launchHomePage()
        commonBridgeFn('launchHomePage', false)
      } else {
        // window.dkBridge?.finish?.()
        commonBridgeFn('finish', false)
      }
    }
  }
  onLeaveEvent() {
    this.changeStayPopup()
    this.back()
  }
  changeStayPopup() {
    const today = dayjs(new Date()).format('YYYY-MM-DD')
    setItem(StayPopupModule.couponType, today)
    StayPopupModule.setCouponShowPopup(false)
    StayPopupModule.updateCouponStayPopup({ couponStayPopup: false, type: StayPopupModule.couponType })
  }
  scrollHandle() {
    this.pageYOffset = window.scrollY
  }

  mounted() {
    this.subs.push(
      scrollStream.subscribe({
        next: () => {
          this.scrollHandle()
        },
      }),
    )
  }

  beforeDestroy() {
    this.subs.forEach((s) => s.unsubscribe())
  }

  render(h: any) {
    return (
      <div class="page-title-wrapper">
        <div
          class="ios-safe-bar"
          style={{
            background: this.allStyle?.background,
            opacity: this.allStyle?.opacity,
          }}
        ></div>
        <div
          class={{ 'page-title': true, 'coustom-border-bottom': this.bottomBorder, fixed: this.fixed }}
          style={{
            background: this.allStyle?.background,
            opacity: this.allStyle?.opacity,
            'box-shadow': this.bottomShadow ? `0px 2px 6px -2px rgba(0,0,0,${0.1 * this.shadowPercentage})` : 'none',
          }}
        >
          <div class="title-left">
            {this.hasBack && (
              <md-icon
                class="title-icon"
                size="lg"
                color={this.color}
                svg
                name={this.selfSvg ? 'icon_back_white' : 'icon_black_back'}
                nativeOnClick={this.leave}
              ></md-icon>
            )}
            <span
              class={{
                'current-title': true,
                active: this.active,
                'no-back': !this.hasBack,
                hide: this.canlisten,
              }}
              style={{
                color: this.color,
              }}
            >
              {this.pageTitle}
            </span>
            <div>{this.$slots.left}</div>
          </div>
          <div class="title-right">{this.$slots.right}</div>
        </div>
        {this.hasNotice && (
          <div class="notice">
            <p class="animate">
              <div class="notice-message">
                <md-icon class="notice-icon" name="icon_broadcast" svg></md-icon>
                <p>{this.noticeMessage}</p>
              </div>
            </p>
          </div>
        )}
        {this.couponStayPopup &&
          this.couponShowPopup &&
          this.hasStayPopup &&
          (this.couponType.includes('retainCouponStayPopup') &&
          (StayPopupModule?.abTestGroup?.groupName === 'A' || StayPopupModule?.abTestGroup?.groupName === 'C') ? (
            <BottomStayPopUp
              onClosePopup={() => StayPopupModule.setCouponShowPopup(false)}
              onLeave={this.onLeaveEvent}
              abTestGroup={StayPopupModule.abTestGroup}
            />
          ) : (
            <StayPop onClosePopup={this.changeStayPopup} onLeave={this.onLeaveEvent} />
          ))}
      </div>
    )
  }
}
