import { DeviceTypeEnum, TakePhotoEnum } from '@base/enum/common'
import dsBridge from '@base/plugins/dsbridge'
import { removeSession, getItem, removeItem } from '@base/lib/storage-handler'
import { getClientEnv, isEnableUseDsbridge, goBack, isApp, goHomeBase, commonFinishFn, goToBill } from './utils'
import { Action as EventAction, IEventParams, PageName } from '@pintar/id-track-event'
import { ITakeKTPPhotoType } from '@base/interface/dsbridge'
import { getLocalChannel } from '@base/lib/checkChannel'
import { withScope, captureMessage } from '@sentry/browser'
import { OtherChannel } from '@base/enum/channel'
import { isOtherChannels } from '@base/lib/channelUtil'
import { StayPopupModule } from '@base/store/stay-popup'
import { handleBackRetention } from './abTest'

const env = getClientEnv()
const enableUseDsbridge = isEnableUseDsbridge()

export const baseBridgeFn = (dsbridgeCb: any, dkbridgeCb: any, webCb?: any) => {
  switch (env) {
    case DeviceTypeEnum.IOS:
      return dsbridgeCb
    case DeviceTypeEnum.ANDROID:
      return enableUseDsbridge ? dsbridgeCb : dkbridgeCb
    default:
      return webCb ? webCb : dkbridgeCb
  }
}

/**
 * 兼容 App & H5 登录方法
 * @tag 公共方法
 * @params fnName, isAsync, data
 */
export const commonBridgeFn = (fnName: string, isAsync: boolean, data = {}) => {
  const _args = Object.values(data)
  console.log(..._args)
  const dkBridgeFn = window.dkBridge?.[fnName]?.(..._args)
  if (isAsync) {
    const dsBridgeCb = new Promise((resolve) => {
      dsBridge.call(`${fnName}`, data, (json: any) => {
        resolve(json)
      })
    })
    return baseBridgeFn(dsBridgeCb, dkBridgeFn)
  }
  return baseBridgeFn(dsBridge.call(`${fnName}`, data), dkBridgeFn)
}

export const hasNativeMethod: (method: string) => Boolean = (method: string) => {
  // 低版本 Android 默认可以调用
  return enableUseDsbridge ? dsBridge.hasNativeMethod(method) : true
}

export const resumeFn: any = () => {
  const dkBridgeCb = new Promise((resolve) => {
    window.dkBridge.resume = () => {
      resolve(null)
    }
  })
  const dsBridgeCb = new Promise((resolve) => {
    dsBridge.registerAsyn('resume', () => {
      resolve(null)
    })
  })
  return baseBridgeFn(dsBridgeCb, dkBridgeCb)
}

export const goBackByNative = (type: 'popstate' | 'custom' = 'custom') => {
  console.log('==> goBackByNative ', type, window.vue.$route, history)
  console.log('history.state, length', history.state, history.length)
  const route = window.vue.$route
  const that = window.vue
  that.$loading?.hide()
  console.log('getLocalChannel:', getLocalChannel())

  // close popup before go back
  // console.log('window.vue.$alert', window.vue.$alert)
  if (window.vue.$alert && window.vue.$alert.forceClose) {
    window.vue.$alert.forceClose()
  }

  if (isApp()) {
    const flag = rewriteExtentionRoute()
    if (flag) return
  }

  if (route.meta?.root) {
    that.$eventTrack({
      action: EventAction.PageEvent,
      target: Number(PageName[route.meta?.backTo]),
    })
    finishH5(route, type)
  } else if (route.meta?.backTo === 'myAccount') {
    if (!enableUseDsbridge && isApp()) {
      commonFinishFn()
    } else {
      that.$router.replace({ name: 'MyAccount' })
    }
    return
  } else if (route.meta?.backTo === 'mySavingAccount') {
    // 由于saving account banner配置的H5 chennel，会出现无法返回
    that.$router.replace({ name: 'MySavingAccountPage', query: { from: 'others' } })
  } else if (route.meta?.back === 'mandatoryBranch') {
    console.log('被return了')
    return
  } else if (route.meta?.back === 'BankList' && isApp()) {
    that.$router.replace({ name: 'BankList' })
  } else if (route.meta?.back === 'FinishBack' && isApp()) {
    backEvent(route, that)
    commonFinishFn()
  } else if (route.name === 'CreditScoreRule' && window.history.length === 1 && isApp()) {
    window.location.href = '/activity/credit-score'
  } else if (route.name === 'JumpOutApp') {
    that.$eventTrack({
      action: EventAction.BackClick,
      locationParams: {
        scenario: 'insurance',
      },
    })
    if (isApp()) {
      commonBridgeFn('finish', false)
    } else {
      that.$router.replace({ name: 'Home' })
    }
  } else if (
    route.name === 'CreditPoster' ||
    route.meta?.back === 'Me' ||
    route.meta?.showStayPopup ||
    window.history.length === 1 ||
    !window.history.state
  ) {
    finishH5(route, type)
    // Stop page return，currently only homepage and code-step page
  } else if (route?.meta?.cantGoBack) {
    console.log('cantGoBack')
    console.log('isOtherChannels', isOtherChannels())
    // 兼容 Mitra 首页关闭 webview
    if (getLocalChannel() === OtherChannel.MITRA) {
      const _window = window as any
      if (typeof _window.android !== 'undefined' && typeof _window.android.close !== 'undefined') {
        try {
          // withScope((scope) => {
          //   scope.setTag('MITRABUKALAPAK', 'suc')
          //   captureMessage(`MITRABUKALAPAK, android.close: ${_window.android.close}`)
          // })
          _window.android.close()
        } catch (e) {
          withScope((scope) => {
            scope.setTag('MITRABUKALAPAK', 'err')
            captureMessage(`MITRABUKALAPAK, android.close err, e: ${JSON.stringify(e)}`)
          })
        }
        return
      }
    }

    const url = getItem('channelBackUrl')
    if (url && isOtherChannels()) {
      removeItem('channelBackUrl')
      if (url.includes('http')) {
        location.replace(url)
      } else {
        location.href = url
      }
    } else {
      //防止h5通过浏览器后退按钮回到kyc页面
      if (route.name === 'Home') {
        that.$router.replace({ name: 'Home' })
        return
      }
      goBack(type)
    }
  } else if (route.meta?.backTo === 'LinearKtp' && isApp()) {
    if (isIosApp()) {
      that.$router.replace({ name: 'LinearKtp' })
    } else {
      commonFinishFn()
    }
  } else if (route.name === 'DBRExceedNotification') {
    if (isApp()) {
      commonFinishFn()
    } else {
      that.$router.replace({ name: 'Home' })
    }
  } else {
    if (route.meta?.back === 'PaymentMethod') {
      removeSession('dueDate')
    }
    console.log('走到了goback， histroy.back()')
    goBack(type)
  }
}

const finishH5 = (route: any, type: any) => {
  if (route.meta?.showStayPopup) {
    if (window.vue.isShowPopUp === undefined || !window.vue.isShowPopUp || !window.vue.isShowPopUpLast) {
      handleBackRetention(showStayPopup)
    } else {
      goBack(type)
    }
    return
  }

  if (route.meta?.backTo === 'Home' && hasNativeMethod('launchHomePage')) {
    console.log('gobacknative homebase')
    goHomeBase()
  } else if (route.meta?.backTo === 'Bill') {
    goToBill()
  } else {
    console.log('gobacknative finish =========>')
    if (isApp() || route.query?.redirect) {
      commonFinishFn()
    } else {
      goBack(type)
    }
  }
}

const showStayPopup = (isStayPopup?: boolean) => {
  if (isStayPopup) {
    StayPopupModule.setCouponShowPopup(isStayPopup)
    return true
  }
  return false
}

const backEvent = (route: any, that: any) => {
  const eventAttribute: IEventParams = {
    action: EventAction.ReturnClick,
    location: PageName[(route?.name as keyof typeof PageName) ? (route.name as keyof typeof PageName) : 'PageNull'],
  }
  const PageEventAttr: IEventParams = {
    action: EventAction.PageEvent,
    location: PageName[route?.name as keyof typeof PageName] ?? 'PageEvent',
  }
  if (route?.params?.id) {
    eventAttribute.locationParams = {
      id: route.params.id,
    }
    PageEventAttr.locationParams = {
      id: route.params.id,
    }
  }
  if (route?.name === 'QuestionDetails' || route?.name === 'Topic') {
    PageEventAttr.target = PageName['HelpCenter']
  }
  that.$eventTrack(eventAttribute)
  if (route?.name === 'QuestionDetails' || route?.name === 'HelpCenter') return
  that.$eventTrack(PageEventAttr)
}

/**
 * 返回 KTP、LIVENESS_KTP、ID_HOLDING 拍照信息
 */
export const takePhotoByNative = async (type: TakePhotoEnum, license?: string) => {
  return new Promise<ITakeKTPPhotoType>((resolve) => {
    dsBridge.call('takePhotoNative', { type, license }, (json) => resolve(json))
  })
}
/** 判断 web 调用原生能力的权限 */
export const enableNativePermissions = (permissions: string[]) => {
  return new Promise<{ success: string[]; failed: string[] }>((resolve) => {
    dsBridge.call('requestPermission', { permissions }, (json: any) => {
      resolve(json)
    })
  })
}

export const registerGoback = () => {
  if (enableUseDsbridge) {
    dsBridge.register('goBack', () => {
      goBackByNative()
    })
  } else {
    window.dkBridge.goBack = () => {
      goBackByNative()
    }
  }
}

const rewriteExtentionRoute = () => {
  let flag = false
  if (location.pathname.includes('/activity/loan/list')) {
    flag = true
    const extensionDue = getItem('extensionDue') || ''
    const due = +new Date(extensionDue)
    const amount = getItem('extensionAmount')
    location.href = `${isApp() ? '' : `/${getLocalChannel()}`}/bill/apply-extension?due=${due}&amount=${amount}`
  }
  if (location.pathname.includes('/docu/preview')) {
    flag = true
    const extensionDue = getItem('extensionDue') || ''
    const due = +new Date(extensionDue)
    const amount = getItem('extensionAmount')
    const extensionList = JSON.parse(getItem('extensionList') || '[]')
    if (extensionDue) {
      //  LoanAgreementList ， LoanAgreementList due
      if (extensionList.length) {
        location.href = `/activity/loan/list?due=${extensionDue}&amount=${amount}`
      } else {
        removeItem('extensionDue')
        removeItem('extensionAmount')
        removeItem('extensionList')
        window.location.href = `${
          isApp() ? '' : `/${getLocalChannel()}`
        }/bill/apply-extension?due=${due}&amount=${amount}`
      }
    } else {
      // isApp() ? window.dkBridge?.finish?.() : (window.location.href = `/${getLocalChannel}/home`)
      isApp() ? commonBridgeFn('finish', false) : (window.location.href = `/${getLocalChannel()}/home`)
    }
  }
  return flag
}

export const isIosApp = () => {
  return env === DeviceTypeEnum.IOS
}

export const getXAppVersion = () => {
  const versionName = commonBridgeFn('getAppVersionName', false) || 'APPVERSION_NAME'
  const versionCode = commonBridgeFn('getAppVersionCode', false) || '9999'
  return `${versionName}(${versionCode})`
}
