import { $toast } from '@/lib/kp-ui'
import _Vue from 'vue'
import { captureException } from '@sentry/browser'
import { AxiosError, AxiosResponse } from 'axios'
import { Action, PageName } from '@pintar/id-track-event'
import { isApp } from '@base/lib/utils'
import { getItem, setItem } from '@base/lib/storage-handler'
import { getLanguage, maintenance } from '@base/lib/utils'
import { getWebHost, updateApp } from '@base/lib/functions'
import { commonBridgeFn, hasNativeMethod } from '@base/lib/common-bridge'
import { CreateStatusCode } from '@base/enum/loan'
import { ApplyModule } from '@base/store/apply'
import { StayPopupModule } from '@base/store/stay-popup'

export const duration = 3000
const hasMask = false

export interface IResponseError<E = null> {
  code: string
  message: string
  extra: E
  status: string
}

export function isAxiosError(options: any): options is AxiosError {
  return options !== undefined
}

const netError: {
  [k: string]: string
} = {
  id: 'Oops. Jaringan tidak tersedia. Periksa koneksi Anda atau ganti jaringan wifi dan coba lagi',
  'en-us': 'Whoops… Network connection is bad. Check your connection or change a wifi and try again',
}

export const handleError = (error: any) => {
  console.log('Error Info: ', error.response, error.message)
  if (isAxiosError(error)) {
    if (error.response) {
      const response = error.response as AxiosResponse<IResponseError>
      switch (response.status) {
        case 400: {
          if (response.data.code === CreateStatusCode.REACH_DBR_RESTRICTION) {
            window.vue.$eventTrack({
              action: Action.DBRRestrictionResult,
              extra: {
                reachDBRRestriction: 'true',
              },
            })

            StayPopupModule.updateCouponStayPopup({ couponStayPopup: false, type: '' })

            const newQuery = window.vue.$route.query
            if (newQuery && (newQuery.productId || newQuery.from || newQuery.isConcurrentLoan)) {
              ApplyModule.saveApplyLoanRouteQuery(window.vue.$route.query)
            }

            window.vue.$router.push({
              name: 'DBRExceedNotification',
            })
          } else {
            $toast.info(response.data?.message ? response.data?.message : error.message, duration, hasMask)
          }
          break
        }
        case 401: {
          //
          // if (window.dkBridge?.login) {
          //   window.vue.$eventTrack({
          //     action: Action.PageEvent,
          //     target: PageName.Login,
          //   })
          //   window.dkBridge.login()
          // }
          // 临时方案: 协议文件禁止跳转
          const url = location.href
          if (url.indexOf('documents/term-of-service') > -1) break
          if (url.indexOf('documents/privacy-policy') > -1) break
          window.vue.$eventTrack({
            action: Action.PageEvent,
            target: PageName.Login,
          })
          commonBridgeFn('login', false)
          break
        }
        case 999: {
          const data = response.data as any
          const message = getItem('language') !== 'id' ? data.englishMessage : data.bahasaMessage
          // if (isApp() && window.dkBridge?.launchHomePage) {
          if (isApp() && hasNativeMethod('launchHomePage')) {
            // window.dkBridge.launchHomePage()
            commonBridgeFn('launchHomePage', false)
          } else {
            maintenance(message)
          }
          break
        }
        case 994: {
          // account security
          const routerInfo = {
            name: window.vue.$route.name,
            query: window.vue.$route.query,
          }
          console.log(response.data)
          setItem('security_router_name', JSON.stringify(routerInfo))
          if (response.data.status === 'REJECTED') {
            // go rejected
            commonBridgeFn('openUrlInNewWindow', false, {
              url: `/security/rejected?rejectUntil=${(response?.data as any)?.rejectSeconds}&necessary=${
                (response?.data as any)?.necessary
              }`,
            })
          } else {
            window.vue.$alert(
              {
                type: 'ACCOUNT_SECURITY',
                hasLeftBtn: (response?.data as any)?.necessary,
                buttonText: window.vue.$t('securityPopupBtn'),
                customClose: (response?.data as any)?.necessary ? false : true,
              },
              (type: string) => goToAccountSecurity(type, response),
            )
          }
          break
        }
        case 996: {
          const data = response.data as any
          updateApp(data.url || `${getWebHost(process.env.VUE_APP_ENV || '')}/h5/download-app-apk?downloadChannel=adv`)
          break
        }
        default: {
          $toast.info(response.data?.message ? response.data?.message : error.message, duration, hasMask)
        }
      }
    } else if (error.request) {
      console.log(error.request, 'has request')
      captureException(error)
      window.vue.$loading.hide()
      $toast.info(netError[getLanguage().toLowerCase()], duration, hasMask)
    } else {
      console.log(error.request, 'no request')
      $toast.info(error.message, duration, hasMask)
    }
  } else {
    console.log('normal error')
    if (process.env.VUE_APP_ENV === 'production') {
      captureException(error)
    }
    if (error.message) {
      $toast.info(error.message, duration, hasMask)
    }
  }
}

export default {
  install(Vue: typeof _Vue, options: any = {}) {
    Vue.handleError = (error: any) => handleError(error)
    Vue.prototype.$handleError = Vue.handleError
  },
}

function goToAccountSecurity(type: string, response: any) {
  if (type === 'submit') {
    commonBridgeFn('openUrlInNewWindow', false, {
      url: `/security/verification-list?requestId=${response.data?.requestId}&necessary=${response.data?.necessary}`,
    })
  } else {
    commonBridgeFn('logout', false)
  }
}
