import { getAbtest } from '@base/api/common'
// import { getCreditLimit } from '@base/api/user'
// import { CreditStatusCode } from '@base/enum/enums'
// import { IFixedLoanProduct } from '@base/interface/loan'
import { StayPopupModule } from '@base/store/stay-popup'

export interface IABTestData {
  testName?: string
  groupName: string
  extraInfo: string
  recordExisted?: string
}

const abtest: {
  [k: string]: IABTestData
} = {}

async function fetchAbtest(name: string): Promise<IABTestData> {
  try {
    const { data } = await getAbtest({ name })
    abtest[name] = {
      groupName: data.groupName,
      extraInfo: data.extraInfo || '',
    }
    return abtest[name]
  } catch (error) {
    abtest[name] = {
      groupName: 'A',
      extraInfo: '',
    }
    return abtest[name]
  }
}

export async function getAbtestByName(name: string): Promise<IABTestData> {
  if (abtest[name]) {
    return abtest[name]
  } else {
    // ，
    const result = await fetchAbtest(name)
    return result
  }
}

/* Deprecated */
// export async function productFilter(products: IFixedLoanProduct[]) {
//   const { data } = await getCreditLimit()
//   if (data.status === CreditStatusCode.APPROVED || data.totalLimit) {
//     return products
//   } else {
//     const branch = await getAbtestByName('First_Loan_Product')
//     const ids = branch.extraInfo
//       .split(',')
//       .map((v: string) => v.trim())
//       .filter((v: any) => v)
//       .map((v: string) => Number.parseInt(v))
//     if (ids.length) {
//       return products.filter((item) => ids.includes(item.productId))
//     }
//     return products
//   }
// }

export async function handleBackRetention(callback?: (stayPopup?: boolean) => void) {
  // Handle Retention PopUp
  const abTestBranch = await getAbtestByName('Retention_PopUp_New')

  const StayPopupKey = 'retainCouponStayPopup'
  const couponStayPopup = !!(abTestBranch?.groupName?.toString() === 'A' || abTestBranch?.groupName?.toString() === 'C')
  console.log('handleBackRetention', JSON.parse(JSON.stringify(abTestBranch)))
  StayPopupModule.updateCouponStayPopup({
    couponStayPopup: couponStayPopup,
    type: StayPopupKey,
    abTestGroup: abTestBranch ? JSON.parse(JSON.stringify(abTestBranch)) : {},
  })

  if (callback) {
    setTimeout(() => {
      callback(couponStayPopup)
    }, 200)
    // callback(couponStayPopup)
  }
}
