


























































import { Component, Prop, Vue } from 'vue-property-decorator'
import { StayPopupModule } from '@base/store/stay-popup'
import { IABTestData } from '@base/lib/abTest'
import { claimCouponRetention } from '@base/api/coupon'
import { ERROR_MESSAGE } from '@base/lib/constant'
import { Action } from '@pintar/id-track-event'
import { goHomeBase } from '@base/lib/utils'

@Component({
  i18n: {
    messages: {
      'en-us': {
        leaveMessageFA1: "You're Almost Done!",
        completeInfoFA1:
          'Are you sure you want to leave? Continue filling our your information for better loan products.',
        leaveMessageFA2: 'Give Us Another Chance!',
        completeInfoFA2: 'Better service for you! Complete your application now and receive a special offer!',

        leaveMessageFC1: "Don't Leave Just Yet!",
        completeInfoFC1:
          "You're almost done with your application! Just a few more minutes and fulfill your loan needs with our loan products.",
        leaveMessageFC2: 'Hey! We Need You!',
        completeInfoFC2: "Complete your application to receive an exclusive offer! Don't miss it out.",

        leaveMessageLA1: 'Last Step!',
        completeInfoLA1: 'Please reconsider and continue your loan application. Quick disbursement process!',
        leaveMessageLA2: 'Stay With Us!',
        completeInfoLA2:
          'We appreciate your interest in our service. Complete your application now to receive an exclusive special offer!',

        leaveMessageLC1: 'Loan Credit Is On The Way!',
        completeInfoLC1: 'You’re almost there! Last press to complete your loan application.',
        leaveMessageLC2: 'Give Us Another Chance',
        completeInfoLC2:
          'We understand your concerns. Complete your application now and get a special discount on interest rates! We value your trust!',

        alreadyClaimTitle: 'Oops! You Already Have a Voucher.',
        alreadyClaimDesc:
          'It looks like you’ve already claimed a voucher. Make sure to use your active coupon first before claiming another one.',

        errorTitle: 'Oops, Something Went Wrong!',
        errorDesc:
          'We couldn’t process your request right now. Continue to complete your application for an interesting loan products!',

        claimSuccess: 'Voucher has been applied. Check the details on loan application page.',
        stay: 'Continue',
        claimOffer: 'Claim Offer',
        leave: 'Exit',
      },
      id: {
        leaveMessageFA1: 'Sedikit lagi selesai!',
        completeInfoFA1: 'Yakin mau keluar sekarang? Lengkapi profil untuk dapat produk pinjaman terbaik.',
        leaveMessageFA2: 'Beri Kami Kesempatan Untuk Memberikan Yang Terbaik!',
        completeInfoFA2: 'Lengkapi proses pengajuan Anda sekarang untuk mendapat penawaran spesial.',

        leaveMessageFC1: 'Jangan Pergi Dulu!',
        completeInfoFC1:
          'Anda hampir menyelesaikan pengajuan Anda! Hanya butuh beberapa menit lagi untuk dapat pinjaman yang Anda butuhkan!',
        leaveMessageFC2: 'Ada Yang Spesial Untuk Anda!',
        completeInfoFC2: 'Selesaikan pengajuan sekarang untuk mendapat penawaran spesial! Jangan sampai terlewat!',

        leaveMessageLA1: 'Tahap Terakhir!',
        completeInfoLA1: 'Yuk, Lanjut proses pengajuan pinjaman Anda. Proses Pencairan cepat!',
        leaveMessageLA2: 'Tetap bersama kami!',
        completeInfoLA2:
          'Kami menghargai keinginanmu untuk mengenal produk kami. Selesaikan pengajuanmu sekarang untuk mendapatkan penawaran spesial!',

        leaveMessageLC1: 'Ada Limit Kredit Menanti Anda!',
        completeInfoLC1: 'Sedikit lagi selesai! Lanjut untuk melengkapi pengajuan pinjaman Anda.',
        leaveMessageLC2: 'Beri Kami Kesempatan Untuk Memberikan Yang Terbaik!',
        completeInfoLC2:
          'Jangan ragukan kami, kami ada untuk Anda! Selesaikan pengajuan Anda sekarang dan dapatkan potongan biaya bunga! Percayakan kepada kami!',

        alreadyClaimTitle: 'Ups! Anda Sudah Memiliki Voucher.',
        alreadyClaimDesc:
          'Sepertinya Anda sudah mengklaim voucher. Pastikan untuk menggunakan kupon aktif Anda terlebih dahulu sebelum mengklaim yang lain.',

        errorTitle: 'Oops, Ada Kesalahan!',
        errorDesc:
          'Kami tidak bisa memproses permintaan kamu saat ini. Lanjut untuk melengkapi pengajuan kamu dan temukan produk pinjaman yang menarik!',

        claimSuccess: 'Voucher berhasil diambil. Cek detailnya pada halaman pengajuan.',
        stay: 'Lanjutkan',
        claimOffer: 'Ambil Penawaran',
        leave: 'Keluar',
      },
    },
  },
})
export default class BottomStayPopUp extends Vue {
  @Prop({ type: Object, default: false }) abTestGroup!: IABTestData

  step = 1
  abTest = this.abTestGroup || {
    groupName: 'B',
    extraInfo: '',
  }
  isPersonalInfoPage = this.$route.fullPath.includes('personal-info') ? 'F' : 'L'
  isOpenStepFirst = true
  isOpenStepSecond = false
  isOpenStepThird = false
  isError = false
  handleEventTracking(actionParam: number) {
    const stringMessage = 'leaveMessage' + this.isPersonalInfoPage + this.abTest.groupName + this.step.toString()
    let message = this.$t(stringMessage).toString()

    if (this.step === 3) {
      message = !this.isError ? this.$t('alreadyClaimDesc').toString() : this.$t('errorDesc').toString()
    }

    if (actionParam === Action.ToastShow) {
      message = this.$t('claimSuccess').toString()
    }

    if (this.abTest?.groupName === 'A' || this.abTest?.groupName === 'C') {
      this.$eventTrack({
        action: actionParam,
        extra: {
          message,
        },
        options: {
          immediately: true,
        },
      })
    }
  }
  created() {
    this.handleEventTracking(Action.DialogDisplay)
  }
  changeStep() {
    if (this.step === 1) {
      this.isOpenStepFirst = false
    } else if (this.step === 2) {
      this.isOpenStepSecond = false
    }

    setTimeout(() => {
      this.step += 1

      if (this.step === 2) {
        this.handleEventTracking(Action.DialogDisplay)
        this.isOpenStepSecond = true
      } else if (this.step === 3) {
        this.handleEventTracking(Action.DialogDisplay)
        this.isOpenStepThird = true
      }
      this.isOpenStepSecond = true
    }, 500)
  }
  async handleClaimOffer() {
    try {
      const response = await claimCouponRetention(this.abTest)

      this.isError = false

      if (response?.data?.message.includes(ERROR_MESSAGE)) {
        return true
      } else {
        this.handleEventTracking(Action.ToastShow)
        this.$toast.succeed(this.$t('claimSuccess') as string, 2000)
        setTimeout(() => {
          window.location.reload()
        }, 2000)
      }

      return false
    } catch (_err) {
      this.isError = true
      return true
    }
  }
  async closeHandler() {
    let isErrorResponse = false
    this.handleEventTracking(Action.DialogConfirm)

    if (this.step === 2) {
      isErrorResponse = await this.handleClaimOffer()

      if (isErrorResponse) {
        this.changeStep()
        return
      }
    }

    if (this.step === 1 || this.step === 3) {
      StayPopupModule.updateCouponStayPopup({ couponStayPopup: false, type: StayPopupModule.couponType })
      StayPopupModule.setCouponShowPopup(false)
    }

    if (this.step === 3) {
      window.vue.isShowPopUpLast = true
      this.$emit('closePopup')
    }
  }
  leavePopup() {
    this.handleEventTracking(Action.DialogCancel)

    if (this.step < 2) {
      this.changeStep()
      return
    }

    this.step = 0
    this.isOpenStepSecond = false
    this.$emit('leave')
  }
}
